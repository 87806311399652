import {Injectable} from '@angular/core';
import {BlRequestService} from '../../support/blRequest.service';
import {Observable} from "rxjs";

@Injectable()
export class BlSampleSourcesRequestsService {

    constructor(private blRequestService: BlRequestService) {
    }

    public list(): Observable<any> {
        const path = '/sample-sources';
        return this.blRequestService.get(path);
    }
}
